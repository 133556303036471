<template>
  <div />
</template>

<script>
import auth from '@/auth'

export default {
  data() {
    return {
      user: auth.user(),
    }
  },
  async mounted(){
    const clientCode = this.$route.query.clientCode

    if (clientCode) {
      try {
        const { data } = await this.$api.get('pictime').connect(this.user.company.id, clientCode)
      } catch(e){
        console.log(e)
        this.$toasted.global.general_error({
          message : 'Error connecting to Pic-Time, please check again.'
        })
      }

      this.$router.push('/settings/integration?pictime=true')
    }
  },
}
</script>
